import { Text, Title } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";

export function ScanTimeoutContent(): JSX.Element {
  const isIOS = isPlatform("ios");

  return (
    <>
      <Title variant="h2" component="h1">
        Tag not detected
      </Title>

      <Text bold>We couldn’t detect a signal from the NFC poster.</Text>
      <Text bold>
        Try connecting to Wi-Fi or removing your phone case. Make sure you&apos;re holding{" "}
        {isIOS ? "the top" : "the back center"} of your phone next to the poster.
      </Text>
    </>
  );
}
