import { Text, Title } from "@clipboard-health/ui-react";

export function NfcDisabledContent(): JSX.Element {
  return (
    <>
      <Title variant="h2" component="h1">
        NFC disabled
      </Title>

      <Text bold>NFC is disabled on your Android device.</Text>
      <Text bold>You must enable NFC in order to clock in/out.</Text>
      <Text bold>
        To enable NFC on your device, please go to device settings and search for “NFC”. You should
        see an option to turn on NFC.
      </Text>
    </>
  );
}
