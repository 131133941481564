import { NfcReaderError, NfcReaderErrorType } from "@src/appV2/Nfc/utils/nfcReaderError";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";

import { ReadingFailureType } from "../types";
import { useNfcHashReader } from "../useNfcHashReader";
import { NfcValidationError } from "./NfcValidationError";
import type { NfcHashValidationAction } from "./types";
import { NfcHashValidationResult, useNfcHashValidation } from "./useNfcHashValidation";

export function useNfcTimekeepingScan(
  options: UseMutationOptions<
    void,
    NfcValidationError,
    {
      shiftId: string;
      clockAction: NfcHashValidationAction;
    }
  > = {}
) {
  const { mutateAsync: validateNfcHash } = useNfcHashValidation();
  const { mutateAsync: startScan } = useNfcHashReader();

  return useMutation({
    mutationFn: async (params) => {
      const { shiftId, clockAction } = params;

      let nfcHash: string | undefined;

      try {
        const scanResult = await startScan();

        if (!scanResult) {
          throw new NfcValidationError({
            type: ReadingFailureType.OTHER,
            code: "NO_HASH_RETURNED",
          });
        }

        nfcHash = scanResult;
      } catch (error) {
        if (!(error instanceof NfcReaderError)) {
          throw new NfcValidationError({
            type: ReadingFailureType.OTHER,
            code: "UNKNOWN_READER_ERROR",
          });
        }

        switch (error.type) {
          case NfcReaderErrorType.SCAN_TIMEOUT: {
            throw new NfcValidationError({
              type: ReadingFailureType.SCAN_TIMEOUT,
              code: error.type,
            });
          }

          case NfcReaderErrorType.DISABLED: {
            throw new NfcValidationError({
              type: ReadingFailureType.NFC_DISABLED,
              code: error.type,
            });
          }

          default: {
            throw new NfcValidationError({
              type: ReadingFailureType.OTHER,
              code: error.type,
            });
          }
        }
      }

      try {
        const validationResult = await validateNfcHash({
          shiftId,
          hashString: nfcHash,
          action: clockAction,
        });

        switch (validationResult) {
          case NfcHashValidationResult.SUCCESS: {
            return;
          }

          case NfcHashValidationResult.INVALID_HASH:
          case NfcHashValidationResult.INACTIVE_HASH:
          case NfcHashValidationResult.INCORRECT_HASH: {
            throw new NfcValidationError({
              type: ReadingFailureType.INVALID_HASH,
              code: validationResult,
            });
          }

          case NfcHashValidationResult.OTHER_FAILURE:
          case NfcHashValidationResult.NETWORK_ERROR: {
            throw new NfcValidationError({
              type: ReadingFailureType.OTHER,
              code: validationResult,
            });
          }

          default: {
            validationResult satisfies never;
          }
        }
      } catch (error) {
        if (error instanceof NfcValidationError) {
          throw error;
        }

        throw new NfcValidationError({
          type: ReadingFailureType.OTHER,
          code: "UNKNOWN_VALIDATION_ERROR",
        });
      }
    },
    ...options,
  });
}
