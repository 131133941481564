import { logEvent } from "@src/appV2/lib/analytics";
import { type USER_EVENTS } from "@src/constants";

import { type NfcHashValidationAction } from "./api/types";
import { type ReadingFailureType } from "./types";

export interface CommonNfcScanEventBase {
  shiftId: string;
  facilityId: string;
  agentId: string;
  // The clock action that the scan is associated with
  shiftClockAction: NfcHashValidationAction;
  // The attempt number in this scan session (since the dialog was opened, eg: 1, 2, ...)
  attemptNumber: number;
}

export type NfcScanStartEvent = CommonNfcScanEventBase;
export interface NfcScanCancelEvent extends CommonNfcScanEventBase {
  // Scan duration in seconds (eg: 5.23 seconds) since the start of the scan
  scanDuration: number;
}
export interface NfcScanFailEvent extends CommonNfcScanEventBase {
  // Scan duration in seconds (eg: 5.23 seconds) since the start of the scan
  scanDuration: number;
  // The reason for the scan failure
  failureReason: ReadingFailureType;
  // Optional error code for specific error identification
  errorCode?: string;
}
export interface NfcScanSuccessEvent extends CommonNfcScanEventBase {
  // Scan duration in seconds (eg: 5.23 seconds) since the start of the scan
  scanDuration: number;
}

export function sendNfcScanEvent(
  props:
    | { type: typeof USER_EVENTS.NFC_SCAN_START; data: NfcScanStartEvent }
    | { type: typeof USER_EVENTS.NFC_SCAN_CANCELLED; data: NfcScanCancelEvent }
    | { type: typeof USER_EVENTS.NFC_SCAN_FAILED; data: NfcScanFailEvent }
    | { type: typeof USER_EVENTS.NFC_SCAN_SUCCEEDED; data: NfcScanSuccessEvent }
) {
  const { type, data } = props;
  logEvent(type, data);
}
