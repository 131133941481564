import { Text, Title } from "@clipboard-health/ui-react";

export function UnknownErrorContent(): JSX.Element {
  return (
    <>
      <Title variant="h2" component="h1">
        NFC error
      </Title>
      <Text bold>Something went wrong, please try again.</Text>
    </>
  );
}
