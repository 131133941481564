import { Capacitor } from "@capacitor/core";
import { Diagnostic } from "@ionic-native/diagnostic";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";
import { USER_EVENTS } from "@src/constants";
import { useEffect, useRef, useState } from "react";

import { AcceptNonInstantPayContent } from "../TimekeepingActions/AcceptNonInstantPayContent";
import { type NfcHashValidationAction } from "./api/types";
import { type CommonNfcScanEventBase, sendNfcScanEvent } from "./nfcEvents";
import { ScanFailedStage } from "./Stages/ScanFailed";
import { ScanInstructionsStage } from "./Stages/ScanInstructions";
import { StartScanStage } from "./Stages/StartScan";
import { type ReadingFailureType } from "./types";

interface WorkerNfcValidationProps {
  shiftId: string;
  metaProps: {
    workerId: string;
    workplaceId: string;
    workplaceName: string;
  };
  nfcTagRequests: NfcTagRequest[];
  clockAction: NfcHashValidationAction;

  // Final states that will close the dialog
  onSuccess: () => void;
  onSkipLocationCheck: () => void;
  onCancel: () => void;
  onNfcNotSupported: () => void;
}

type ScanningStage = "start-scan" | "instructions" | "failure" | "accept-non-instant-pay";
export function WorkerNfcValidation(props: WorkerNfcValidationProps) {
  const {
    shiftId,
    metaProps: { workplaceName, workplaceId, workerId },
    nfcTagRequests,
    clockAction,
    onCancel,
    onSuccess,
    onSkipLocationCheck,
    onNfcNotSupported,
  } = props;

  const scanStartTimeRef = useRef(Date.now());
  const [currentAttemptNumber, setCurrentAttemptNumber] = useState(0);
  const [currentScanStage, setCurrentScanStage] = useState<ScanningStage>("start-scan");
  const [readingFailureError, setReadingFailureError] = useState<{
    type: ReadingFailureType;
    code: string | undefined;
  }>();

  useEffect(() => {
    async function verifyNfcCapability() {
      const isNfcSupported = Capacitor.isNativePlatform() && (await Diagnostic.isNFCPresent());
      if (!isNfcSupported) {
        onNfcNotSupported();
      }
    }

    void verifyNfcCapability();
  }, [shiftId, workerId, onNfcNotSupported]);

  const resetAfterClose = () => {
    setCurrentAttemptNumber(0);
    setCurrentScanStage("start-scan");
    setReadingFailureError(undefined);
  };

  const trackScanAttempt = (attemptNumber: number) => {
    setCurrentAttemptNumber(attemptNumber);
    scanStartTimeRef.current = Date.now();
  };

  const getScanDurationInSeconds = (): number => {
    return (Date.now() - scanStartTimeRef.current) / 1000;
  };

  const getCommonScanEventProps = (attemptNumber: number): CommonNfcScanEventBase => ({
    shiftId,
    agentId: workerId,
    facilityId: workplaceId,
    shiftClockAction: clockAction,
    attemptNumber,
  });

  function startScan() {
    const newAttemptNumber = currentAttemptNumber + 1;
    trackScanAttempt(newAttemptNumber);

    sendNfcScanEvent({
      type: USER_EVENTS.NFC_SCAN_START,
      data: {
        ...getCommonScanEventProps(newAttemptNumber),
      },
    });
  }

  function onScanSuccess() {
    onSuccess();
    resetAfterClose();

    sendNfcScanEvent({
      type: USER_EVENTS.NFC_SCAN_SUCCEEDED,
      data: {
        ...getCommonScanEventProps(currentAttemptNumber),
        scanDuration: getScanDurationInSeconds(),
      },
    });
  }

  function onScanFailure(failureType: ReadingFailureType, errorCode?: string) {
    setReadingFailureError({
      type: failureType,
      code: errorCode,
    });
    setCurrentScanStage("failure");

    sendNfcScanEvent({
      type: USER_EVENTS.NFC_SCAN_FAILED,
      data: {
        ...getCommonScanEventProps(currentAttemptNumber),
        scanDuration: getScanDurationInSeconds(),
        failureReason: failureType,
        errorCode,
      },
    });
  }

  function onScanCancel() {
    sendNfcScanEvent({
      type: USER_EVENTS.NFC_SCAN_CANCELLED,
      data: {
        ...getCommonScanEventProps(currentAttemptNumber),
        scanDuration: getScanDurationInSeconds(),
      },
    });

    onCancel();
    resetAfterClose();
  }

  return (
    <>
      {currentScanStage === "start-scan" && (
        <StartScanStage
          clockAction={clockAction}
          shiftId={shiftId}
          workplaceName={workplaceName}
          nfcTagRequests={nfcTagRequests}
          onStartScan={() => {
            startScan();
          }}
          onSuccess={() => {
            onScanSuccess();
          }}
          onFail={(failureType: ReadingFailureType, errorCode?: string) => {
            onScanFailure(failureType, errorCode);
          }}
          onCancel={() => {
            onScanCancel();
          }}
          onHelpScanningPoster={() => {
            setCurrentScanStage("instructions");
          }}
        />
      )}

      {currentScanStage === "instructions" && (
        <ScanInstructionsStage
          shiftId={shiftId}
          workplaceName={workplaceName}
          nfcTagRequests={nfcTagRequests}
          clockAction={clockAction}
          onStartScan={() => {
            startScan();
          }}
          onSuccess={() => {
            onScanSuccess();
          }}
          onFail={(failureType: ReadingFailureType, errorCode?: string) => {
            onScanFailure(failureType, errorCode);
          }}
          onCancel={() => {
            onScanCancel();
          }}
        />
      )}

      {currentScanStage === "failure" && (
        <ScanFailedStage
          workplaceName={workplaceName}
          nfcTagRequests={nfcTagRequests}
          failureType={readingFailureError?.type}
          errorCode={readingFailureError?.code}
          attemptNumber={currentAttemptNumber}
          onBack={() => {
            setCurrentScanStage("start-scan");
          }}
          onSkipVerification={() => {
            setCurrentScanStage("accept-non-instant-pay");
          }}
          onHelpClick={() => {
            setCurrentScanStage("instructions");
          }}
        />
      )}

      {currentScanStage === "accept-non-instant-pay" && (
        <AcceptNonInstantPayContent
          onAccept={() => {
            setCurrentScanStage("start-scan");
            onSkipLocationCheck();
          }}
          onCancel={() => {
            setCurrentScanStage("start-scan");
          }}
        />
      )}
    </>
  );
}
