import { CbhIcon } from "@clipboard-health/ui-components";
import { ExternalLink, LoadingButton, Text, Title } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";
import { Box, DialogContent, Stack } from "@mui/material";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";
// eslint-disable-next-line no-restricted-imports
import nfcValidationVideoUrl from "@src/assets/images/nfc-video-poster.jpg";

import { NfcValidationError } from "../api/NfcValidationError";
import { type NfcHashValidationAction } from "../api/types";
import { useNfcTimekeepingScan } from "../api/useNfcTimekeepingScan";
import { NfcLocationsDescription } from "../NfcLocationsDescription";
import { ReadingFailureType } from "../types";

interface ScanInstructionsStageProps {
  shiftId: string;
  workplaceName: string;
  clockAction: NfcHashValidationAction;
  nfcTagRequests: NfcTagRequest[];

  onStartScan: () => void;
  onSuccess: () => void;
  onFail: (failureType: ReadingFailureType, errorCode?: string) => void;
  onCancel: () => void;
}

export function ScanInstructionsStage(params: ScanInstructionsStageProps) {
  const {
    shiftId,
    workplaceName,
    nfcTagRequests,
    clockAction,
    onStartScan,
    onSuccess,
    onFail,
    onCancel,
  } = params;
  const { mutateAsync: startScan, isLoading: isScanning } = useNfcTimekeepingScan();

  const isIOS = isPlatform("ios");
  const phonePosition = isIOS ? "the top" : "the back center";

  return (
    <>
      <DialogContent sx={{ paddingX: 5, textAlign: "left" }}>
        <Stack direction="row" alignItems="center" paddingY={4} spacing={4}>
          <CbhIcon type="qr-code" size="large" />
          <Title variant="h3" component="h1">
            How to scan
          </Title>
        </Stack>
        <Stack spacing={5}>
          <NfcLocationsDescription
            workplaceName={workplaceName}
            nfcTagRequests={nfcTagRequests}
            align="left"
          />
          <Stack spacing={2}>
            <Text bold>NFC tips</Text>
            <ol>
              <li>
                <Text>Poor connection? Try connecting to Wi-Fi.</Text>
              </li>
              <li>
                <Text>Try removing your phone case.</Text>
              </li>
              <li>
                <Text>
                  Hold <b>{phonePosition}</b> of your phone within 1 centimeter of the rectangle on
                  the poster.
                </Text>
              </li>
            </ol>
          </Stack>
          <Text>Still having trouble? Watch the video below</Text>
          <Box>
            <ExternalLink to="https://www.youtube.com/watch?v=WuXARGIxdGg" target="_blank">
              <img src={nfcValidationVideoUrl} alt="NFC usage explainer video" />
            </ExternalLink>
          </Box>
        </Stack>
      </DialogContent>
      <DialogFooter
        orientation="vertical"
        closeButtonAriaLabel="Cancel"
        onClose={() => {
          onCancel();
        }}
      >
        <LoadingButton
          fullWidth
          isLoading={isScanning}
          variant="contained"
          size="large"
          onClick={async () => {
            onStartScan();

            try {
              await startScan({ shiftId, clockAction });
              onSuccess();
            } catch (error) {
              if (error instanceof NfcValidationError) {
                onFail(error.type, error.code);
                return;
              }

              onFail(ReadingFailureType.OTHER, "UNKNOWN_ERROR");
            }
          }}
        >
          Begin NFC scan
        </LoadingButton>
      </DialogFooter>
    </>
  );
}
