import { type ReadingFailureType } from "../types";

export class NfcValidationError extends Error {
  type: ReadingFailureType;
  code: string;

  constructor(params: { type: ReadingFailureType; code: string }) {
    const { type, code } = params;

    super(type);

    this.type = type;
    this.code = code;
  }
}
