import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { LoadingButton, Title } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";
import { useQueryClient } from "@tanstack/react-query";

import { NfcValidationError } from "../api/NfcValidationError";
import { type NfcHashValidationAction } from "../api/types";
import { useNfcTimekeepingScan } from "../api/useNfcTimekeepingScan";
import { NfcLocationsDescription } from "../NfcLocationsDescription";
import { ReadingFailureType } from "../types";
import { clockActionToReadableString } from "../utils";

interface StartScanStageProps {
  shiftId: string;
  clockAction: NfcHashValidationAction;
  workplaceName: string;
  nfcTagRequests: NfcTagRequest[];

  onStartScan: () => void;
  onSuccess: () => void;
  onFail: (failureType: ReadingFailureType, errorCode?: string) => void;
  onCancel: () => void;
  onHelpScanningPoster: () => void;
}

export function StartScanStage(props: StartScanStageProps) {
  const {
    shiftId,
    clockAction,
    workplaceName,
    nfcTagRequests,

    onStartScan,
    onSuccess,
    onFail,
    onCancel,
    onHelpScanningPoster,
  } = props;

  const queryClient = useQueryClient();
  const { mutateAsync: startScan, isLoading: isScanning } = useNfcTimekeepingScan();

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="info"
        title={
          <Title variant="h3" component="h1">
            {clockActionToReadableString(clockAction)}
          </Title>
        }
        description={
          <Box px={9}>
            <NfcLocationsDescription
              workplaceName={workplaceName}
              nfcTagRequests={nfcTagRequests}
            />
          </Box>
        }
      />

      <DialogFooter
        orientation="vertical"
        closeButtonAriaLabel="Cancel"
        sx={{ paddingTop: 10 }}
        onClose={async () => {
          void queryClient.cancelQueries({ queryKey: ["nfc-hash-scan"] });
          onCancel();
        }}
      >
        <LoadingButton
          fullWidth
          isLoading={isScanning}
          variant="contained"
          size="large"
          onClick={async () => {
            onStartScan();

            try {
              await startScan({ shiftId, clockAction });
              onSuccess();
            } catch (error) {
              if (error instanceof NfcValidationError) {
                onFail(error.type, error.code);
                return;
              }

              onFail(ReadingFailureType.OTHER, "UNKNOWN_ERROR");
            }
          }}
        >
          Begin NFC scan
        </LoadingButton>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={() => {
            onHelpScanningPoster();
          }}
        >
          Help me scan the poster
        </Button>
      </DialogFooter>
    </>
  );
}
