import { type ApiResponse, post, put } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { logError } from "@src/appV2/lib/analytics";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const disableShiftNfcCheckRequestSchema = z.object({
  shiftId: z.string(),
  workerId: z.string(),
});

export type DisableShiftNfcCheckRequest = z.infer<typeof disableShiftNfcCheckRequestSchema>;

function getShiftConversionReason(shiftId: string) {
  return `Shift converted to location check - shift ${shiftId} got converted at ${new Date().toISOString()}.\nReason: NFC not supported by device.`;
}

export function useDisableShiftNfcCheck<T extends DisableShiftNfcCheckRequest>(
  options: UseMutationOptions<ApiResponse<void>, AxiosError, T> = {}
): UseMutationResult<ApiResponse<void>, AxiosError, T> {
  return useMutation({
    mutationFn: async (data: T) => {
      const { shiftId, workerId } = data;

      const response = await put({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shift/changeNFCTag`,
        data: { shiftId },
        requestSchema: disableShiftNfcCheckRequestSchema.pick({ shiftId: true }),
        responseSchema: z.any(),
      });

      try {
        await post({
          url: `${environmentConfig.REACT_APP_BASE_API_URL}/account-log/create`,
          data: {
            text: getShiftConversionReason(shiftId),
            accountId: workerId,
            shiftId,
            meta: {
              shiftId,
            },
          },
          responseSchema: z.any(),
        });
      } catch (error) {
        logError(APP_V2_APP_EVENTS.DISABLE_NFC_CHECK_ACCOUNT_LOG_FAILED, {
          error,
          metadata: {
            shiftId,
            workerId,
            eventType: "account-log",
          },
        });
      }

      return response;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.DISABLE_NFC_CHECK_FAILED,
    },
    ...options,
  });
}
