import { Text, Title } from "@clipboard-health/ui-react";

export function InvalidHashContent(): JSX.Element {
  return (
    <>
      <Title variant="h2" component="h1">
        Incorrect poster
      </Title>
      <Text bold>
        You scanned the wrong NFC poster. This usually happens when a facility has multiple units,
        that each have a unique poster.
      </Text>

      <Text bold>Make sure you&apos;re scanning the right poster and try again.</Text>
    </>
  );
}
