import { CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";
import { type ReactElement } from "react";

interface NfcLocationsDescriptionProps {
  nfcTagRequests: NfcTagRequest[];
  workplaceName: string;
  align?: "left" | "center";
}

export function NfcLocationsDescription(params: NfcLocationsDescriptionProps): ReactElement {
  const { nfcTagRequests, workplaceName, align = "center" } = params;

  const tagLocations = nfcTagRequests
    .map((tagRequest) => tagRequest.tagLocation)
    .filter<string>(
      (tagLocation): tagLocation is string => isDefined(tagLocation) && tagLocation !== ""
    );

  const alignItems = align === "center" ? "center" : "flex-start";
  const paddingLeft = align === "left" ? 6 : 0;

  return (
    <>
      {tagLocations.length === 0 && (
        <Text>
          If you need help locating an NFC Time Clock poster at {workplaceName}, please ask a
          workplace admin.
        </Text>
      )}
      {tagLocations.length === 1 && (
        <>
          <Text>{workplaceName} has an NFC Time Clock poster at the following location: </Text>
          <Stack
            key={tagLocations[0]}
            mt={4}
            spacing={1}
            justifyContent={alignItems}
            alignItems={alignItems}
            paddingLeft={paddingLeft}
          >
            <CbhIcon type="map-pin" size="small" />
            <Text variant="body1" sx={{ fontStyle: "italic" }}>
              {tagLocations[0]}
            </Text>
          </Stack>
        </>
      )}
      {tagLocations.length > 1 && (
        <Box>
          <Text>{workplaceName} has NFC Time Clock posters at the following locations: </Text>
          <Stack mt={4} spacing={1}>
            {tagLocations.map((tagLocation) => (
              <Stack
                key={tagLocation}
                direction="row"
                spacing={1}
                justifyContent={alignItems}
                alignItems={alignItems}
                paddingLeft={paddingLeft}
              >
                <CbhIcon type="map-pin" size="small" />
                <Text variant="body1" sx={{ fontStyle: "italic" }}>
                  {tagLocation}
                </Text>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}
