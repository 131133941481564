import { Illustration, Pill } from "@clipboard-health/ui-components";
import { DialogContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";

import { ReadingFailureType } from "../types";
import { InvalidHashContent } from "./ScanFailedContent/InvalidHashContent";
import { NfcDisabledContent } from "./ScanFailedContent/NfcDisabledContent";
import { ScanTimeoutContent } from "./ScanFailedContent/ScanTimeoutContent";
import { UnknownErrorContent } from "./ScanFailedContent/UnknownErrorContent";

export interface ScanFailedStageProps {
  workplaceName: string;
  nfcTagRequests: NfcTagRequest[];
  attemptNumber: number;
  failureType?: ReadingFailureType;
  errorCode?: string;
  onBack: () => void;
  onSkipVerification: () => void;
  onHelpClick: () => void;
}

export function ScanFailedStage(props: ScanFailedStageProps) {
  const {
    workplaceName,
    nfcTagRequests,
    attemptNumber,
    failureType = ReadingFailureType.OTHER,
    errorCode,
    onBack,
    onSkipVerification,
    onHelpClick,
  } = props;

  function getScanFailedContent(props: {
    workplaceName: string;
    nfcTagRequests: NfcTagRequest[];
    attemptNumber: number;
    failureType: ReadingFailureType;
    errorCode?: string;
  }) {
    const { failureType } = props;

    switch (failureType) {
      case ReadingFailureType.INVALID_HASH: {
        return <InvalidHashContent />;
      }

      case ReadingFailureType.NFC_DISABLED: {
        return <NfcDisabledContent />;
      }

      case ReadingFailureType.SCAN_TIMEOUT: {
        return <ScanTimeoutContent />;
      }

      case ReadingFailureType.OTHER: {
        return <UnknownErrorContent />;
      }

      default: {
        return <UnknownErrorContent />;
      }
    }
  }

  const isSkipButtonVisible = failureType === ReadingFailureType.INVALID_HASH || attemptNumber > 1;

  return (
    <>
      <DialogContent sx={{ paddingX: 5, textAlign: "left" }}>
        <Stack
          flexGrow="1"
          spacing={7}
          paddingX={8}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Stack alignItems="center" justifyContent="center">
            <Illustration type="error" />
          </Stack>

          {getScanFailedContent({
            workplaceName,
            nfcTagRequests,
            attemptNumber,
            failureType,
            errorCode,
          })}

          <Pill label={`Error code: ${errorCode ?? failureType}`} />
        </Stack>
      </DialogContent>
      <DialogFooter orientation="vertical" closeButtonAriaLabel="Cancel">
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            onBack();
          }}
        >
          Try again
        </Button>
        {isSkipButtonVisible && (
          <Button
            fullWidth
            variant="destructive"
            size="large"
            onClick={() => {
              onSkipVerification();
            }}
          >
            Skip NFC verification
          </Button>
        )}
        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={() => {
            onHelpClick();
          }}
        >
          I need help
        </Button>
      </DialogFooter>
    </>
  );
}
